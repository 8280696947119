import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/pic11.jpg'
import musclers2 from '../assets/images/3musclers2.jpg'
import logo2 from '../assets/images/01.png'

const AboutUs = props => (
  <Layout>
    <Helmet>
      <title>關於我們｜三健客3musclers健身俱樂部</title>
      <meta name="description" content="三健客3musclers健身俱樂部，是意指如同三劍客中主角聚集了志同道合的夥伴，在這個健身空間中一起自我修練，並一起互相陪伴。在三健客一品牌元素中，我們也在健身房空間中加入了許多穿刺、切削等動作要素，將品牌理念更具象化的表現出來。" />
		<meta name='keywords' content='三健客,健身房,健身房推薦,健身俱樂部,健身房課程,私人健身房,教練課程,健身房教練,團體課程'/>
		<link rel='canonical' href='https://www.3musclers.com/aboutUs'/>
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>關於三健客</h1>
          </header>
          <span className="image main">
            <img src={musclers2} alt="3musclers" />
          </span>
          <span className="image left">
            {/* <img src={logo2} alt="3musclers" /> */}
          </span>
          <br />
          <p>
            三健客 3 Musclers取材自法國文學家大仲馬於1844年出版的小說📓『三劍客
            Les Trois Mousquetaires』，是一本關於 愛、勇氣與希望的故事。而
            #三健客
            這個品牌，就如同三劍客中主角聚集了一群志同道合的夥伴一起面對強權，也是由一群健身訓練者🏋‍♂🏋‍♀🏋‍♂聚集而成，#在健身這個自我修煉的路途中互相陪伴。
          </p>
          <p>
            在三健客的品牌元素中，我們取其 🤺#劍客與健客🤺
            之諧音，將西洋劍中穿刺、切削等動作要素，融合在品牌與空間視覺元素中。可以看到不論是識別本身或甚至是場館空間設計，都充斥著不斷
            #交叉穿刺、#長線條交錯的元素利用，讓品牌精神與調性可以透過一些視覺的手法安排，不斷地往復貫徹執行。
          </p>
          <p>
            其中識別的動態更是將這個「#不斷穿刺」的元素繼續延伸，以刀型元素象徵健客的精神，在不斷穿刺的動態中雕琢出三健客的
            #品牌識別，象徵
            #健身運動的千錘百鍊。品牌識別在三個刀型元素的貫穿之下漸漸形成，結合正看是M，橫看是3的造型雙關手法，最後整體外框大型更是擷取自🤺
            #西洋劍Épée
            的紡錘型造型語彙，由寬收窄、剛中帶柔的把三健客想要傳達出的品牌理念具象化。在鋼鐵般的壯碩💪外表下，其實是個暖男，三健客正是這樣的形象的最佳代言人。
          </p>
			 <iframe width="560" height="315" src="https://www.youtube.com/embed/l8NJj1jZjnQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </section>
    </div>
  </Layout>
)

export default AboutUs
